<!--
 * @Date: 2023-02-16 14:48:25
 * @LastEditors: 'lidianbin' 'lidianbin@coocaa.com'
 * @LastEditTime: 2024-09-04 10:46:39
 * @FilePath: \cms_main_site\src\components\layout\Main.vue
-->
<template>
  <el-container direction="vertical">
    <Header :class="{'fixed': isScroll}" ref="header" :menu="menu" :site-info="siteInfo"></Header>
    <el-main>
      <keep-alive>
        <router-view :key="$route.name" v-if="isKeepAlive"></router-view>
      </keep-alive>
      <router-view :key="$route.name" v-if="!isKeepAlive"></router-view>
    </el-main>
    <Footer :site-info="siteInfo"></Footer>
  </el-container>
</template>

<script>
import Header from './Header.vue'
import Footer from './Footer.vue'

export default {
  components: {
    Header,
    Footer
  },
  computed: {
    menu () {
      return (this.$store.state.site.navList || [])
      // .concat({
      //   navTitle: '智能体',
      //   target: 1, // 1 新窗口打开
      //   isStress: true, // 是否强调
      //   navUrl: '/agents/center'
      // })
    },
    isKeepAlive () {
      return !!this.$route.meta.isKeepAlive
    },
    siteInfo () {
      return this.$store.state.site
    }
  },
  data () {
    return {
      breadcrumb: [],
      isScroll: false
    }
  },
  methods: {},
  mounted () {
    window.addEventListener('scroll', () => {
      if (document.documentElement.scrollTop > 0) {
        this.isScroll = true
      } else {
        this.isScroll = false
      }
    })
  },
  created () {
    this.$bus.$on('breadcrumb-change', (breadcrumb) => {
      this.breadcrumb = breadcrumb
    })
  }
}
</script>

<style lang="stylus" scoped>
.el-main
  background #fff
  padding 0
  min-height calc(100vh - 265px)
.fixed
  top 0
  left 0
  width 100%
  z-index $highestIndex
  background #fff
  position fixed
</style>
