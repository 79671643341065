import { render, staticRenderFns } from "./PageMain.vue?vue&type=template&id=5682bdfc&scoped=true"
import script from "./PageMain.vue?vue&type=script&lang=js"
export * from "./PageMain.vue?vue&type=script&lang=js"
import style0 from "./PageMain.vue?vue&type=style&index=0&id=5682bdfc&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5682bdfc",
  null
  
)

export default component.exports