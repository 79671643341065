/*
 * @Date: 2023-02-16 14:48:25
 * @LastEditors: 'lidianbin' 'lidianbin@coocaa.com'
 * @LastEditTime: 2024-09-05 17:21:53
 * @FilePath: \cms_main_site\src\auth.js
 */
import Vue from 'vue'
import store from '@/store'
import router from './router'
import { getSiteInfo } from '@/services/common'
import { checkUat, ssoLogout } from '@/services/login'
import { consts } from '@cseed/public-utils'
import { getCookie, getTokenName, findTreeBindResource, getUrlParams } from '@/utils/common.js'
import lodash from 'lodash'
Vue.prototype.$siteInitData = async function () {
  const curDomain = location.protocol + '//' + window.location.host
  const domain = curDomain !== 'https://127.0.0.1:8223' ? curDomain : 'http://172.20.151.12:6767'
  // 从缓存中拿站点信息，防止“url锚点发送改变时重复获取”
  if (store.state.site) {
    return Promise.resolve()
  }
  return getSiteInfo({ domain }).then(async data => {
    const siteInfo = JSON.parse(JSON.stringify(data))
    // 遍历树，找到绑定资源的节点并请求资源列表
    try {
      siteInfo.navList = await findTreeBindResource(siteInfo)
    } catch (err) {
      console.log(err)
    }
    if (data.configuration) {
      siteInfo.configuration = consts.evil(data.configuration)
    } else {
      siteInfo.configuration = {}
    }
    store.dispatch('setSite', siteInfo)
  })
}
Vue.prototype.$login = async function () {
  //
}
// 当url的参数为 #/version/add3?responseToken=xxxxx 时无法正确获取参数，需要额外处理
function fixRouterQueryWithHashToken (route) {
  let routeQuery = lodash.cloneDeep(route.query)
  let routeHash = route.hash
  const token = routeQuery.responseToken
  if (!token && routeHash) {
    const [hashParams, queryString] = routeHash.split('?')
    routeQuery = getUrlParams(queryString) || {}
    routeHash = hashParams
  }
  return {
    routeHash,
    routeQuery
  }
}

/**
 * 对接单点登录SSO
 * 待优化：要保持 Promise 中各处的 reject 返回的格式一致
 */
Vue.prototype.$checkSSO = async function (toRouter) {
  return new Promise((resolve, reject) => {
    // 情况1：页面正常跳转时，从缓存中获取用户信息，防止多次进行登录认证
    if (store.state.user.token) {
      return resolve(store.state.user)
    }
    const { routeHash, routeQuery } = fixRouterQueryWithHashToken(toRouter)
    // 页面强制刷新时，从 cookie 中 token  获取用户信息，防止多次进行登录认证
    // 若url存在responseToken，说明是SSO登录成功后重定向到当前页面
    const userToken = routeQuery.responseToken || getCookie(getTokenName())
    if (userToken) {
      // 强制刷新时要重新获取初始化数据
      return checkUat({ accessToken: userToken }).then((data) => {
        if (!data || !data.uniqueId) {
          store.dispatch('clearUserInfo')
          // eslint-disable-next-line prefer-promise-reject-errors
          return reject({ msg: '用户未登录', code: '' })
        }
        const user = {
          token: userToken,
          name: data.userNickname,
          uniqueId: data.uniqueId,
          identif: data.userName,
          avatar: data.avatar
        }
        store.dispatch('cacheUserInfo', user) // 缓存用户信息
        if (routeQuery.responseToken) {
          delete routeQuery.responseToken
          router.replace({
            ...toRouter,
            hash: routeHash,
            query: routeQuery
          }) // 去除链接上携带的 token 信息
        }
        resolve(user)
      }).catch((e) => {
        store.dispatch('clearUserInfo')
        reject(e)
      })
    }
    // eslint-disable-next-line prefer-promise-reject-errors
    return reject({ msg: '用户未登录', code: '' })
  })
}
Vue.prototype.$ssoLogout = function (callback, backUrl) {
  const token = store.state.user.token
  store.dispatch('clearUserInfo')
  if (typeof callback === 'function') {
    // eslint-disable-next-line standard/no-callback-literal
    callback(() => {
      ssoLogout({ backUrl, token })
    })
  } else {
    ssoLogout({ backUrl, token })
  }
}
