export const PAGE_TYPE_PAGE = '5'
export const PAGE_TYPE_DOC = '4'
export const PAGE_TYPE_OTHER = '0'

// 图片类型
export const MATERIAL_TYPE_IMG = 1 // 图片
export const MATERIAL_TYPE_VIDEO = 2 // 视频
export const MATERIAL_TYPE_OTHER = 3 // 其他

/** 表格更多按钮 */
export function tableOprateList (list) {
  const arr = [
    { title: '查看', command: 'read', fnName: 'routerPathTo', icon: 'el-icon-reading', divided: false },
    { title: '编辑', command: 'edit', fnName: 'routerPathTo', icon: 'el-icon-edit', divided: false },
    { title: '删除', command: 'delete', fnName: 'delData', icon: 'el-icon-delete', divided: true },
    { title: '移动到', command: 'move', fnName: 'moveTo', icon: 'el-icon-position', divided: true },
    { title: '收藏', command: 'collect', fnName: 'collectDoc', icon: 'el-icon-star-off', divided: true },
    { title: '导出', command: 'export', fnName: 'exportDoc', icon: 'el-icon-download', divided: true },
    { title: '取消收藏', command: 'cancelCollect', fnName: 'collectDoc', icon: 'el-icon-star-on', divided: true }
  ]
  return list ? arr.filter(item => list.some(e => e === item.command)) : arr
}

export const APP_DEMO_TYPE_EXPERIENCE = 1 // 链接
export const APP_DEMO_TYPE_PLUGIN = 2 // 插件
export const APP_DEMO_TYPE_PAGE = 3 // 可是
export const PARAMS_TYPE_RADIO = 1 // 单选
export const PARAMS_TYPE_CHECKBOX = 2 // 多选
export const PARAMS_TYPE_TEXT = 3 // 文本
export const PARAMS_TYPE_IMAGE = 4 // 图片
export const PARAMS_TYPE_VIDEO = 5 // 视频

export const TAG_HOT = -1 // 热门
export const TAG_NEW = -2 // 最新
export const TAG_OTHER = -98 // 其他
export const TAG_MYLIKE = -99 // 收藏

export const CONTACT_PHONE = '400-1688-880' // 客户服务与投诉热线

export const ORDER_TYPE = [{ value: 1, label: '产品使用咨询' }, { value: 2, label: '业务受损' }, { value: 3, label: '业务不可用' }]
export const REPLY_TYPE = [{ value: 1, label: '已回复 ' }, { value: 2, label: '未回复' }]
