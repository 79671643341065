<template>
  <el-container direction="vertical">
    <Header
      :class="{'fixed': isScroll}"
      ref="header"
      :menu="menu"
      :site-info="siteInfo"
      :is-sub="isSubSite"
    ></Header>
    <el-main>
      <router-view></router-view>
    </el-main>
    <Footer :site-info="siteInfo"></Footer>
  </el-container>
</template>

<script>
import Header from './Header.vue'
import Footer from './Footer.vue'
import { getSiteInfoByPage } from '@/services/common'
import { consts } from '@cseed/public-utils'
import { findTreeBindResource } from '@/utils/common.js'

export default {
  components: {
    Header,
    Footer
  },
  data () {
    return {
      breadcrumb: [],
      // menu: [],
      siteInfo: {},
      isScroll: false,
      isSubSite: false
    }
  },
  methods: {
    checkMenuLevel (menuItem) {
    }
  },
  computed: {
    menu () {
      return this.$store.state.site.navList
    }
  },
  mounted () {
    window.addEventListener('scroll', () => {
      if (document.documentElement.scrollTop > 0) {
        this.isScroll = true
      } else {
        this.isScroll = false
      }
    })
  },
  created () {
    // this.$bus.$on('breadcrumb-change', (breadcrumb) => {
    //   this.breadcrumb = breadcrumb
    // })
    const pageUniqueId = this.$route.params.id
    getSiteInfoByPage({ pageUniqueId }).then(async data => {
      // 判断是否返回的数据为空
      if (data.code === 1000) {
        // this.menu = this.$store.state.site.navList
        this.siteInfo = this.$store.state.site
      } else {
        // this.menu = data.navList
        this.siteInfo = data
        // 遍历树，找到绑定资源的节点并请求资源列表
        try {
          await findTreeBindResource(this.siteInfo)
        } catch (err) {
          console.log(err)
        }
        if (data.configuration) {
          this.siteInfo.configuration = consts.evil(data.configuration)
        } else {
          this.siteInfo.configuration = {}
        }
        if (data.domain !== this.$store.state.site.domain) {
          const code = this.siteInfo?.code
          const routeTo = code ? `/product/${code}/${data.mainPageUniqueId}` : `/page/${data.mainPageUniqueId}`
          this.$router.replace({
            path: routeTo,
            params: this.$route.params,
            query: this.$route.query,
            hash: this.$route.hash
          }).catch(() => { })
          this.$refs.header.homehref = routeTo + '?redirect_original=true'
          this.isSubSite = true
        }
      }
    })
  }
}
</script>

<style lang="stylus" scoped>
.el-main
  background #fff
  padding 0
  min-height calc(100vh - 265px)
.fixed
  top 0
  left 0
  width 100%
  z-index $highestIndex
  background #fff
  position fixed
</style>
