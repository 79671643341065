<template>
  <div class="right-floating-layer" v-if="!isMobile">
    <!-- 点赞 -->
    <!-- <div v-if="!isMobile" class="btn" @click="handleLike">
      <svg-icon :icon-class="likeIcon"></svg-icon>
    </div> -->

    <!-- zhiCustomBtn class 不能去除且必须和 index.html 中的 初始化代码保持一致，为了自定义智齿的咨询按钮 -->
    <!-- <coocaa-popover placement="right" trigger="hover" popper-class="cust-popover">
      <div class="custserv-item-wrapper">
        <a :href="`tel:${contactPhone}`">
          <div class="custserv-item mt-2 p-3">
            <div class="item-icon mr-3"><svg-icon icon-class="phone"></svg-icon></div>
            <div class="item-content">
              <div class="content-title c-font-medium-14">咨询电话</div>
              <div class="content-subtitle c-font-regular-12 tel">{{contactPhone}}</div>
            </div>
          </div>
        </a>
        <div class="custserv-item mt-2 p-3 zhiCustomBtn">
          <div class="item-icon mr-3"><svg-icon icon-class="after-sales"></svg-icon></div>
          <div class="item-content">
            <div class="content-title c-font-medium-14">在线客服</div>
            <div class="content-subtitle c-font-regular-12">实时沟通,快速解决您的问题</div>
          </div>
          <div class="tip"><svg-icon icon-class="right-arrow"></svg-icon></div>
        </div>
        <div class="custserv-item mt-2 p-3" @click="handleOpenConsult">
          <div class="item-icon mr-3"><svg-icon icon-class="qa"></svg-icon></div>
          <div class="item-content">
            <div class="content-title c-font-medium-14">在线留言</div>
            <div class="content-subtitle c-font-regular-12">问题留言,我们会尽快联系您</div>
          </div>
          <div class="tip"><svg-icon icon-class="right-arrow"></svg-icon></div>
        </div>
      </div>
      <div slot="reference" class="btn-group higlight c-font-medium-14">
        <div class="btn"><svg-icon class="icon" icon-class="cust-serv"></svg-icon><div class="mt-2">售前咨询</div>
        </div>
      </div>
    </coocaa-popover> -->
    <div class="btn-group higlight c-font-medium-14" @click="handleOpenConsult">
      <div class="btn"><svg-icon class="icon" icon-class="cust-serv"></svg-icon><div class="mt-2">在线留言</div></div>
    </div>
    <div class="btn-group c-font-medium-14 mt-3" v-if="$route.path.startsWith('/document')">
      <!-- <div class="btn mb-4" @click="handleCreateTicket"><svg-icon class="icon mb-1" icon-class="after-sales"></svg-icon><div class="c-font-medium-12 small">售后</div></div> -->
      <div class="btn" @click="handleOpenDrawer('drawer')"><svg-icon class="icon mb-1" icon-class="feedback"></svg-icon><div class="c-font-medium-12 small">反馈</div></div>
    </div>

    <!-- 评论 -->
    <!-- <el-tooltip :content="$t('site.myComment')" placement="left">
      <div class="btn" v-if="commentShow" @click="handleOpenDrawer('comment')">
        <i class="el-icon-s-comment"></i>
      </div>
    </el-tooltip> -->
    <!-- 反馈侧边弹窗 -->
    <FeedbackDrawer
      ref="drawer"
      v-if="resetDrawer"
      @closed="handleClosed"
    ></FeedbackDrawer>
    <!-- 评论侧边弹窗 -->
    <!-- <CommentDrawer
      ref="comment"
      v-if="resetDrawer"
      :resourceUniqueId="resourceUniqueId"
      @closed="handleClosed"
    /> -->
  </div>
</template>

<script>
import FeedbackDrawer from '@/components/FeedbackDrawer.vue'
// import CommentDrawer from '@/components/CommentDrawer.vue'
import { pageLikeService } from '@/services/feedback'
// import { ssoLogin } from '@/services/login'
import { PAGE_TYPE_DOC, PAGE_TYPE_PAGE, CONTACT_PHONE } from '@/utils/constants'
import { getPageInfo, guildSsoLogin, isMobile } from '@/utils/common'
export default {
  components: {
    FeedbackDrawer
    // CommentDrawer
  },
  data () {
    return {
      isLikeActived: false,
      resourceUniqueId: '',
      pageInfo: {},
      resetDrawer: false,
      commentShow: false,
      isMobile: isMobile(),
      contactPhone: CONTACT_PHONE,
      partnerid: '',
      uname: ''
    }
  },
  computed: {
    likeIcon () {
      return this.isLikeActived ? 'like_fill' : 'like'
    }
  },
  watch: {
    $route (to, from) {
      if (
        this.$route.meta.pageType === PAGE_TYPE_PAGE ||
        this.$route.meta.pageType === PAGE_TYPE_DOC
      ) {
        this.commentShow = true
        this.resourceUniqueId = this.$route.params[this.$route.meta.idKey]
      } else {
        this.commentShow = false
        this.resourceUniqueId = ''
      }
      this.pageInfo = getPageInfo(this.$route)
      if (to.path !== from.path) {
        this.isLikeActived = false
      }
    }
  },
  methods: {
    handleLike () {
      this.pageInfo = { ...getPageInfo(this.$route) }
      // $checkSSO 判断用户是否登录
      this.$checkSSO(this.$route)
        .then(() => {
          const isLike = !this.isLikeActived
          pageLikeService({
            ...this.pageInfo,
            status: isLike ? 1 : 2
          }).then(() => {
            this.isLikeActived = isLike
          })
        })
        .catch(() => {
          // ssoLogin(this.$route.fullPath)
          !this.$store.state.isLoginNotify && guildSsoLogin.call(this)
        })
    },
    handleOpenDrawer (type = 'drawer') {
      this.resetDrawer = true
      this.$nextTick(() => {
        this.$refs[type].handleOpenDrawer()
      })
    },
    handleClosed () {
      this.resetDrawer = false
    },
    handleCreateTicket () {
      window.open(
        this.$router.resolve({ name: 'createTicket' }).href,
        '_blank'
      )
    },
    handleOpenConsult () {
      if (this.$route.name === 'consultFormPage') {
        return
      }
      window.open(
        this.$router.resolve({ name: 'consultFormPage' }).href,
        '_blank'
      )
    }
  },
  mounted () {
    // zc('dynamic_ready') // 创建动态自定义咨询入口
    this.$checkSSO(this.$route)
      .then((userInfo) => {
        const { uniqueId, name } = userInfo
        this.partnerid = uniqueId
        this.uname = name
      })
      .catch(() => {
        this.partnerid = 'uniqueId'
        this.uname = '游客123'
      }).finally(() => {
        window.zc('config', {
          partnerid: this.partnerid,
          uname: this.uname
        })
        // https://www.zhichi.com/developerdocs/web/web_api.html#%E8%87%AA%E5%AE%9A%E4%B9%89%E5%AE%A2%E6%88%B7%E7%AB%AF
        window.zc('dynamic_ready') // 创建动态自定义咨询入口
      })
  }
}
</script>
<style lang="stylus">
.custserv-item-wrapper
  display flex-wrap
  a
    text-decoration none
    color inherit
  .custserv-item
    cursor pointer
    display flex
    align-items center
    border-radius 8px
    height 64px
    box-sizing border-box
    &:hover
      background #EEF6FF
      color var(--brand_5)
    .item-content .tel
      color var(--brand_5)
    .item-icon
      width 40px
      height 40px
      line-height 40px
      font-size 21px
      border-radius 50%
      text-align center
      background #EEF6FF
    .tip
      width 30px
      font-size 10px
      text-align center
</style>
<style lang="stylus" scoped>
.right-floating-layer
  z-index $feedBackIndex
  position fixed
  bottom 65px
  right 29px
  .btn-group
    padding 14px
    border-radius 24px
    width 50px
    box-shadow 0px 4px 24px 0px rgba(34, 83, 134, 0.16)
    text-align center
    box-sizing border-box
    display flex
    flex-direction column
    background-color var(--white_1)
    &.higlight
      cursor pointer
      box-shadow 0px 4px 16px 0px rgba(83, 123, 255, 0.24)
      background linear-gradient(360deg, #50A7FF 0%, #5379FF 100%)
      color var(--white_1)
      &:hover
        background-color var(--brand_5)
      .btn:hover
        color var(--white_1)
    .btn
      cursor pointer
      display flex
      align-items center
      flex-direction column
      &:hover
        color var(--brand_5)
      .small
        font-size 11px
        text-wrap-mode nowrap
      .icon
        height 21px
        width 21px
</style>
<style lang="stylus">
.cust-popover
  border-radius 8px
</style>
