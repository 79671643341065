<template>
  <coocaa-dialog
    class="global-dialog"
    :visible.sync="dialogVisible"
    :closeOnPressEscape="false"
    :closeOnClickModal="false"
    @close="onDialogClose"
    width="560px"
    style="margin-top:10vh"
  >
    <div slot="title">
      <div class="global-dialog-title">
        <span class="c-font-medium-28">联系我们</span>
        <span class="c-font-medium-14 mt-5">点击咨询联系我们,解锁AI生产力,让效率飞升</span>
      </div>
    </div>
    <div class="global-dialog-content pt-3">
      <!-- zhiCustomBtn class 不能去除且必须和 index.html 中的 初始化代码保持一致，为了自定义智齿的咨询按钮 -->
      <!-- <div class="btn zhiCustomBtn" @click="handleOpenKefu">在线客服咨询</div> -->
      <div class="btn c-font-medium-14" @click="handleOpenConsult"><svg-icon class="mr-2 c-font-medium-18" icon-class="after-sales"></svg-icon>在线留言</div>
    </div>
  </coocaa-dialog>
</template>

<script>
import Storage from '@/utils/storage'
const MAX_CLOSE_TIME = 24 // 最长关闭时间为 24小时
export default {
  data () {
    return {
      dialogVisible: false,
      isDynamicReady: false
    }
  },
  watch: {
    dialogVisible: {
      immediate: true,
      handler (val) {
        if (val && !this.isDynamicReady) {
          this.$nextTick(() => {
            // https://www.zhichi.com/developerdocs/web/web_api.html#%E8%87%AA%E5%AE%9A%E4%B9%89%E5%AE%A2%E6%88%B7%E7%AB%AF
            window.zc('dynamic_ready') // 创建动态自定义咨询入口
            this.isDynamicReady = true
          })
        }
      }
    }
  },
  methods: {
    onDialogClose () {
      // gdct 即 global dialog close Time
      Storage.$set('gdct', new Date().getTime())
    },
    handleOpenKefu () {
      this.dialogVisible = false
    },
    handleOpenConsult () {
      window.open(
        this.$router.resolve({ name: 'consultFormPage' }).href,
        '_blank'
      )
    }
  },
  created () {
    const gdct = Storage.$get('gdct') || (new Date().getTime() - 1000 * 60 * 60 * MAX_CLOSE_TIME)
    const closeTime = (new Date().getTime() - gdct) / 1000 / 60 / 60
    // 若用户在 24小时内关闭过弹窗，则不再弹窗
    if (closeTime < MAX_CLOSE_TIME) {
      return
    }
    // 初始化后间隔 10分钟 全局弹窗
    setTimeout(() => {
      // 若用户处于“在线留言”页面，则不用弹窗，因为弹窗也是要引导用户到 “在线留言”
      if (this.$route.name === 'consultFormPage') {
        return
      }
      Storage.$set('gdct', new Date().getTime()) // 为了防止用户不手动点击关闭按钮，而是直接刷新页面，导致重复弹窗，故在弹窗出现时就记录下关闭时间
      this.dialogVisible = true
    }, 1000 * 60 * 0.5)
  }
}
</script>
<style lang="stylus" scoped>
.global-dialog:deep(.coocaa-dialog)
  color var(--w_text_1)
  height 258px
  margin-top 25vh
  background url('@/assets/images/global-dialog.png') no-repeat center
  box-shadow 0px 4px 24px 0px rgba(65, 104, 178, 0.3)
  background-color #155eff
  border-radius 8px
.global-dialog:deep(.coocaa-dialog__header)
  border-bottom none
  padding-top 45px
  .coocaa-dialog__close
    font-size 24px
.global-dialog:deep(.coocaa-dialog__close)
  color var(--w_text_1)
.global-dialog-title,
.global-dialog-content
  display flex
  flex-direction column
  justify-content center
  align-items center
  .btn
    width 136px
    padding 13px
    border-radius 8px
    background-color var(--w_text_1)
    color var(--text_3)
    cursor pointer
    display flex
    justify-content center
    align-items center
    &:hover
      background-color var(--w_text_2)
</style>
